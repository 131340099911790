import React, { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationListAction } from '../Redux/Action/HomeAction';
import moment from 'moment';

const NotificationHeader = () => {
    const dispatch = useDispatch();
    const { notifyList } = useSelector((state) => state?.home)

    console.log("notifyList", notifyList)

    const getApiCall = () => {
        let apiRes = dispatch(getNotificationListAction());
        console.log("apiRes", apiRes)
    }

    useEffect(() => {
        getApiCall();
    }, [])

    return (
        <>
            <div className="common-icon-side d-flex align-items-center">
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={require("../Assets/images/notify.svg").default} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${notifyList?.data?.length > 0 && `height-set-notify`}`}
                        style={{
                            position: 'absolute',
                            inset: '0px auto auto 0px',
                            transform: 'translate(-397.333px, 48px)',
                        }}>
                        {
                            Array.isArray(notifyList?.data) && notifyList?.data?.length > 0 ?
                                notifyList?.data?.map((res) => {
                                    return <>
                                        <Dropdown.Item>
                                            <div className="notifi-pop">
                                                <div className="d-flex justify-content-between  mb-2 gap-2 flex-column-reverse">
                                                    <h2>{res?.title ?? 'N/A'}</h2>
                                                    <p>{res?.created_at && moment(res?.created_at).format('hh:mm:a')} | {res?.created_at ? moment(res?.created_at).format('DD-MMM-YYYY') : 'N/A'}</p>
                                                </div>
                                                <p>
                                                    {res?.message ?? 'N/A'}
                                                </p>
                                            </div>
                                        </Dropdown.Item>
                                    </>
                                }) :
                                <p className='data-not-available'>
                                    No Data Found
                                </p>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}

export default NotificationHeader