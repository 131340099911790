import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { convertUTCToLocalWithAMPM } from '../utilis/commonFunction';

const CartDeliveryCharges = ({ formik }) => {
    const { deliveryList } = useSelector((state) => state?.cart);

    const handleDeliveryChange = (deliveryId) => {
        formik.setFieldValue('selectedDeliveryCharge', deliveryId);
    };

    useEffect(() => {
        if (deliveryList?.data?.length > 0 && !formik.values.selectedDeliveryCharge) {
            formik.setFieldValue('selectedDeliveryCharge', deliveryList.data[0]._id);
        }
    }, [deliveryList, formik.values.selectedDeliveryCharge, formik]);

    return (
        <>
            <div className="delievery-address" >
                {
                    Array.isArray(deliveryList?.data) && deliveryList?.data?.length > 0 &&
                    deliveryList?.data?.map((res) => {
                        return <>
                            < div className="schedule-delievery">
                                <h2 className="mb-0">
                                    {res?.type == 1 ? `Morning` : res?.type == 2 ? `Noon` : res?.type == 3 ? `Evening` : res?.type == 4 ? `Instant Charges` : `Subscribe`} <span>(${res?.price || '0'})</span>
                                </h2>
                                <div className="form-check d-flex flex-row-reverse">
                                    <input
                                        className="form-check-input ms-2"
                                        type="radio"
                                        name="selectedDeliveryCharge"
                                        id={`delivery-${res._id}`}
                                        value={res._id}
                                        disabled={formik.values.instant_delivery}
                                        checked={formik.values.selectedDeliveryCharge === res?._id}
                                        onChange={() => formik.setFieldValue('selectedDeliveryCharge', res?._id)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={`delivery-${res._id}`}
                                    >
                                        {`${convertUTCToLocalWithAMPM(res?.from)} - ${convertUTCToLocalWithAMPM(res?.to)}`}
                                    </label>
                                </div>
                            </div>
                        </>
                    })

                }
            </div>
        </>
    )
}

export default CartDeliveryCharges