import React, { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import Googlemaps from "../Components/Googlemaps";
import GoogleAutoComplete from "../Components/GoogleAutoComplete";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userEditProfile } from "../Redux/Action/AuthAction";
import { useQuery } from "../utilis/commonFunction";

export default function AddAddress() {
  const dispatch = useDispatch();
  const query = useQuery();
  const token = query.get("token");
  const [show, setShow] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [intialState, setIntialState] = useState({
    label: '',
    lat: '',
    lng: '',
    value: {
      description: '',
      place_id: '',
    },
  });

  const [address, setAddress] = useState({
    label: '',
    lat: '',
    lng: '',
    value: {
      description: '',
      place_id: '',
    },
  });

  const handleSaveLocation = () => {
    setIntialState({ ...intialState, ...address });
    handleClose();
  };

  const handleClose = () => {
    setShow(false);
    setAddress({
      label: '',
      lat: '',
      lng: '',
      value: {
        description: '',
        place_id: '',
      },
    })
  }
  const handleShow = () => setShow(true);

  const formik = useFormik({
    initialValues: {
      address: "",
      landmark: "",
      location: "",
    },
    onSubmit: async (values) => {
      console.log(values);
      if (!intialState.label) {
        return toast.warn("Please Add Location");
      }
      let formData = new FormData();
      if (values.landmark) {
        formData.append("landmark", values.landmark);
      }
      formData.append("address", intialState.label);
      formData.append("place_id", intialState.value.place_id);
      formData.append("is_address_completed", 1);
      formData.append("lat", intialState.lat);
      formData.append("lng", intialState.lng);
      setIsLoader(true);
      await dispatch(userEditProfile({ token, formData })).then((res) => {
        console.log("address userEdit res", res);
        if (res?.payload?.status === 200) {
          toast.success("Profile register successfully");
          localStorage.removeItem("isProfile");
          localStorage.removeItem("isLocationAdded");
          localStorage.setItem("token", token);
          window.history.replaceState(null, '', '/');
          window.location.href = '/'
        } else {
          toast.error(res?.payload?.message);
        }
        setIsLoader(false);
        formik.resetForm();
      });
    },
  });

  return (
    <div>
      <Container fluid>
        <div className="main-form">
          <Row>
            <Col
              lg={6}
              className="px-0 d-flex align-items-center  justify-content-center"
            >
              <div className="cmn-form">
                <h2 className="heading-30">Your Address</h2>
                <p className="sub-16 text-center">Fill your address</p>
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <div className="cmn-form-fields">
                    <Form.Group className="mb-3 form-inner">
                      <img
                        src={require("../Assets/images/locico.svg").default}
                      />
                      <GoogleAutoComplete
                        intialState={intialState}
                        setIntialState={setIntialState}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 form-inner">
                      <img
                        src={require("../Assets/images/locico.svg").default}
                      />

                      <Form.Control
                        type="text"
                        placeholder="Landmark (Optional)"
                        {...formik.getFieldProps("landmark")}
                      />
                    </Form.Group>
                    <button
                      type="button"
                      className="current-location"
                      onClick={handleShow}
                    >
                      <img
                        src={
                          require("../Assets/images/smalllocation.svg").default
                        }
                      />
                      Choose Current Location
                    </button>
                    <Button type="submit" className="cmn-red-btn mb-0" disabled={isLoader ? true : false}>
                      {isLoader &&
                        <>
                          <div class="spinner-border spinner-border-sm" role="status" >
                          </div>{" "}
                        </>
                      }
                      Next
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={6} className="px-0">
              <div className="left-img">
                <img src={require("../Assets/images/leftimg.jpeg")} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Modal
        className="cmn-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="address-main">
            <Googlemaps
              setAddress={setAddress}
              address={address}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleSaveLocation}
            className="cmn-red-btn mb-0 mt-0"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
