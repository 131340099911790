import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Navbar,
  Dropdown,
  Row,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuthToken from "../Hook/useAuthToken";
import DeliveryDetailsModal from "../Modals/deliveryDetailsModal";
import "rc-slider/assets/index.css";
import "react-range-slider-input/dist/style.css";
import RangeSlider from "react-range-slider-input";
import Slider from "rc-slider";
import useGetMyProfile from "../Hook/useGetUserProfile";
import { getUserProfile } from "../Redux/Action/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import ProfileHeader from "../CommonComponent/ProfileHeader";
import CartHeader from "../CommonComponent/CartHeader";
import NotificationHeader from "../CommonComponent/NotificationHeader";
import useCheckRoute from "../Hook/useCheckRoute";
import Offcanvas from "react-bootstrap/Offcanvas";
import { searchAction } from "../Redux/Action/HomeAction";
import SearchBarComp from "../CommonComponent/SearchBarComp";
import { ImagePath } from "../utilis/ImageUrl";
import useCityFromLatLng from "../Hook/useCityFromLatLng";

export default function Header() {
  const dispatch = useDispatch();
  const tokenData = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();
  const userProfileData = useGetMyProfile();
  const isHomeRoute = useCheckRoute();
  const { searchData } = useSelector((state) => state.home);
  const cityLocation = useCityFromLatLng(
    userProfileData?.address?.location?.coordinates[1],
    userProfileData?.address?.location?.coordinates[0]
  );
  const [rating, setRating] = useState(0);
  const [distance, setDistance] = useState([0, 100]);
  const [isGoodToGo, setIsGoodToGo] = useState(false);

  useEffect(() => {
    if (tokenData) {
      dispatch(getUserProfile());
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ratingParam = queryParams.get("rating");
    const distanceParam = queryParams.get("distance");
    const goodToGoParam = queryParams.get("goodToGo");

    if (ratingParam) setRating(parseFloat(ratingParam));
    if (distanceParam)
      setDistance(distanceParam.split(",").map((val) => parseFloat(val)));
    if (goodToGoParam) setIsGoodToGo(goodToGoParam === "true");
  }, [location.search]);

  const login = () => {
    navigate("/login");
  };

  const Signup = () => {
    navigate("/create-account");
  };
  const [show, setShow] = useState(false);
  const [dropdownToggle, setDropdownToggle] = useState(false);
  const dropdownRef = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleToogle = () => setDropdownToggle(!dropdownToggle);
  const handleToogleClose = () => setDropdownToggle(false);

  const getApiCall = (searchValue) => {
    dispatch(searchAction({ search: searchValue }));
  };

  const handleSearch = (searchValue) => getApiCall(searchValue);

  const handleApply = () => {
    const queryParams = new URLSearchParams();
    if (rating) {
      queryParams.set("rating", rating);
    }
    if (distance[0] > 0) {
      queryParams.set("distance", distance.join(","));
    }
    if (isGoodToGo) {
      queryParams.set("goodToGo", isGoodToGo);
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
    handleToogleClose();
  };

  const handleClearAll = () => {
    setRating(0);
    setDistance([0, 100]);
    setIsGoodToGo(false);
    navigate(location.pathname, { replace: true });
    handleToogleClose();
  };

  const handleCheckboxChange = (e) => {
    setIsGoodToGo(e.target.checked);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleToogleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  console.log("rating", rating);
  console.log("distance", distance);
  console.log("good", isGoodToGo);
  console.log("toggle", dropdownToggle);

  return (
    <>
      <div className="header p-0">
        <Container>
          <Navbar expand="lg" className="p-0">
            <Navbar.Brand>
              <div className="logo">
                <Link to="/">
                  <img src={require("../Assets/images/logo.png")} />
                </Link>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="mobile-scroll">
                <Row className="align-items-center">
                  <Col xxl={7} xl={6} lg={6} md={6} sm={12}>
                    <div className="header-inner-left">
                      {tokenData && (
                        <div className="location" onClick={handleShow}>
                          <span>
                            <img
                              src={
                                require("../Assets/images/location.svg").default
                              }
                            />
                            City
                          </span>
                          <h3>
                            {cityLocation?.city || "Others"}
                            <svg
                              width="10"
                              height="6"
                              viewBox="0 0 10 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M-3.45377e-08 0.790129C0.0990553 0.490866 0.338439 0.309621 0.557186 0.115732C0.73466 -0.0423295 1.02357 -0.0275771 1.20724 0.124162C1.25057 0.15999 1.28978 0.200032 1.32899 0.240074C2.52385 1.4582 3.71664 2.67844 4.90943 3.89657C4.93625 3.92397 4.95483 3.9598 4.98165 3.99773C5.02912 3.95137 5.05595 3.92608 5.08277 3.89868C6.28795 2.67001 7.48899 1.44134 8.69416 0.212677C8.90672 -0.00650182 9.17293 -0.071834 9.38755 0.0904428C9.58566 0.240075 9.76314 0.429749 9.91378 0.62996C10.0541 0.817527 10.0128 1.07253 9.86425 1.25799C9.84155 1.28539 9.81679 1.31279 9.79203 1.33808C8.33922 2.82175 6.88434 4.30753 5.43153 5.79121C5.21278 6.01249 4.95895 6.06096 4.72576 5.92187C4.66798 5.88815 4.61639 5.83968 4.56686 5.79121C3.11199 4.30753 1.65711 2.82386 0.208428 1.33597C0.119691 1.24535 0.0680999 1.1189 -4.41183e-08 1.00931C-4.0894e-08 0.935546 -3.76698e-08 0.861784 -3.45377e-08 0.790129Z"
                                fill="#171717"
                              />
                            </svg>
                          </h3>
                        </div>
                      )}

                      <SearchBarComp onSearch={handleSearch} />

                      {searchData?.data?.length > 0 && (
                        <div className="search-results">
                          {Array.isArray(searchData?.data) &&
                            searchData?.data?.length > 0 &&
                            searchData?.data?.slice(0, 10).map((res) => {
                              return (
                                <div key={res?._id} className="results-box">
                                  <Link
                                    to={`${
                                      res?.type === "shop"
                                        ? `/single-restaurant/${res?._id}`
                                        : `/single-dish/${res?._id}`
                                    }`}
                                  >
                                    <img src={ImagePath(res?.image)} />
                                    <p>{res.name}</p>
                                  </Link>
                                </div>
                              );
                            })}
                        </div>
                      )}

                      {tokenData && isHomeRoute && (
                        <div className="filter-dropdowns">
                          <Dropdown ref={dropdownRef}>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                              onClick={handleToogle}
                            >
                              <img
                                src={
                                  require("../Assets/images/filters.svg")
                                    .default
                                }
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu show={dropdownToggle}>
                              <div className="dropdown-menu-filters">
                                <div className="d-flex justify-content-between mb-3">
                                  <h2>Filters</h2>
                                  <p
                                    onClick={handleClearAll}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Clear all
                                  </p>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                  <h3 className="mb-0 me-4">Ratings</h3>
                                  <Slider
                                    value={rating}
                                    onChange={setRating}
                                    min={0}
                                    max={5}
                                    step={0.5}
                                  />
                                </div>
                                <div className="mb-3">
                                  <h3>Distance</h3>
                                  <RangeSlider
                                    value={distance}
                                    onInput={setDistance}
                                    min={0}
                                    max={100}
                                    step={1}
                                  />
                                </div>
                                <div className="mb-3 mt-4 d-flex align-items-center justify-content-between">
                                  <h3 className="mb-0">Good to go</h3>
                                  <div className="form-check">
                                    <input
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        border: "1px solid #A72A2F",
                                      }}
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                      onChange={handleCheckboxChange}
                                      checked={isGoodToGo}
                                    />
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="cmn-red-btn mb-0 w-100"
                                  onClick={handleApply}
                                >
                                  Apply
                                </button>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  </Col>

                  {tokenData ? (
                    <Col
                      xxl={isHomeRoute ? 5 : 5}
                      xl={isHomeRoute ? 6 : 6}
                      lg={isHomeRoute ? 6 : 6}
                      md={isHomeRoute ? 6 : 6}
                      sm={isHomeRoute ? 12 : 12}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <div className="notify-tab d-flex">
                        {/* Notification */}
                        <NotificationHeader />

                        {/* Cart */}
                        <CartHeader />

                        {/* Profile */}
                        <ProfileHeader userProfileData={userProfileData} />
                      </div>
                    </Col>
                  ) : (
                    <Col
                      xxl={5}
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <div className="header-inner-right">
                        <div className="header-right-links">
                          <Link to="/BecamePartner">Become a Partner</Link>
                          <Link to="/deliver-with-us">Deliver with us</Link>
                        </div>
                        <div className="header-right-buttons">
                          <button onClick={login}>Login</button>
                          <button onClick={Signup}>Sign up</button>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>

        {/* Delivery Details Modal */}
        <DeliveryDetailsModal show={show} handleClose={handleClose} />
      </div>
    </>
  );
}
