import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CommonApi from "../../Service/CommonApi";
import { constructQueryString } from "../../utilis/commonFunction";

const listCategoriesAction = createAsyncThunk(
    'list/categorie', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get('/categories', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const shopDetailsAction = createAsyncThunk(
    'details/shop', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/shops/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const listShopAction = createAsyncThunk(
    'list/shop', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await CommonApi.get(`/list-shops${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const shopViewAllAction = createAsyncThunk(
    'list/shop-view-all', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await CommonApi.get(`/view-shops${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const similarProductListAction = createAsyncThunk(
    'list/similar-product', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/category/${reqBody?.id}/products?product_id=${reqBody?.product_id}
            `)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const productListAction = createAsyncThunk(
    'list/product', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const queryString = constructQueryString(payload);
            const response = await CommonApi.get(`/shops/${id}/products${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const productDetailsAction = createAsyncThunk(
    'details/product', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/product/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const productLikeAction = createAsyncThunk(
    'like/product', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.post(`/product/like-dislike`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const shopLikeAction = createAsyncThunk(
    'like/shop', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.post(`/shop/like-dislike`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const searchAction = createAsyncThunk(
    'get/search', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            console.log("quey", queryString)
            const response = await CommonApi.get(`/shops-products${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const dealofdayAction = createAsyncThunk(
    'get/deal-of-day', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            console.log("quey", queryString)
            const response = await CommonApi.get(`/deal-of-the-day${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const favortieAction = createAsyncThunk(
    'get/favortie', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            console.log("quey", queryString)
            const response = await CommonApi.get(`/favorites${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const getNotificationListAction = createAsyncThunk(
    'get/notification', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await CommonApi.get(`/notification${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const getDownloadInvoiceUrl = createAsyncThunk(
    'url/download', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await CommonApi.get(`/download-invoice${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const prepareOrderAction = createAsyncThunk(
    'prepare-order/details', async (reqBody, thunkAPI) => {
        try {
            const response = await CommonApi.get(`/preparing-order`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export {
    listCategoriesAction,
    listShopAction,
    shopDetailsAction,
    productListAction,
    productDetailsAction,
    productLikeAction,
    shopLikeAction,
    searchAction,
    similarProductListAction,
    dealofdayAction,
    favortieAction,
    shopViewAllAction,
    getNotificationListAction,
    getDownloadInvoiceUrl,
    prepareOrderAction
}