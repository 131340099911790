import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { editCartAction, listCartAction } from '../Redux/Action/CartAction';
import { toast } from 'react-toastify';
import { ImagePath } from '../utilis/ImageUrl';
import DeleteModal from '../Modals/deleteModal';

const CartCard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cartList } = useSelector((state) => state.cart);
    const [cartItems, setCartItems] = useState({});
    const [tempCartItems, setTempCartItems] = useState({});
    const [cartId, setCartId] = useState("");
    const [deleteAccOpen, setDeleteAccOpen] = useState(false)

    useEffect(() => {
        dispatch(listCartAction());
    }, [dispatch]);

    useEffect(() => {
        if (cartList?.data) {
            const initialItems = cartList.data.reduce((acc, item) => {
                acc[item._id] = {
                    quantity: item.quantity || 1,
                    price: item.product.price || 0,
                    totalPrice: item.total_price || item.product.price
                };
                return acc;
            }, {});
            setCartItems(initialItems);
            setTempCartItems(initialItems);
        }
    }, [cartList]);

    const handleQuantityChange = async (id, delta) => {
        const currentItem = cartItems[id];
        const newQuantity = Math.max((currentItem.quantity || 1) + delta, 1);
        setTempCartItems(prevItems => ({
            ...prevItems,
            [id]: {
                ...prevItems[id],
                quantity: newQuantity,
                totalPrice: newQuantity * prevItems[id].price
            }
        }));
        let success;
        if (currentItem.quantity != newQuantity) {
            success = await handleSubmit(id, newQuantity);
        }
        if (!success) {
            setTempCartItems(prevItems => ({
                ...prevItems,
                [id]: {
                    ...cartItems[id]
                }
            }));
        }
    };

    const handleDeleteCart = async (id) => {
        setCartId(id)
        setDeleteAccOpen(true)
    };

    const handleSubmit = async (cartId, cartQty) => {
        let formdata = {
            quantity: cartQty
        };
        try {
            const response = await dispatch(editCartAction({ id: cartId, formdata }));
            if (response?.payload?.status === 200) {
                dispatch(listCartAction())
                setCartItems(prevItems => ({
                    ...prevItems,
                    [cartId]: {
                        ...prevItems[cartId],
                        quantity: cartQty,
                        totalPrice: cartQty * prevItems[cartId].price
                    }
                }));
                return true;
            } else {
                toast.error(response?.payload?.message);
                return false;
            }
        } catch (error) {
            toast.error('Failed to update quantity');
            return false;
        }
    };

    return (
        <>
            <Col lg={5}>
                <div className="cart-card-left">
                    {
                        cartList?.count > 0 &&
                        <h3>{cartList?.count} Products</h3>
                    }

                    {
                        Array.isArray(cartList?.data) && cartList?.data?.length > 0 ? (
                            cartList?.data?.slice(0, 3).map((res) => {
                                const item = tempCartItems[res._id] || {};
                                return (
                                    <>
                                        <div className="add-products-left mb-3" key={res._id}>
                                            <div className="add-product-card">
                                                {/* <Link to={`/single-dish/${res?.product_id}`} className='no-underline'>
                                                </Link> */}
                                                <img
                                                    className="product-img"
                                                    src={ImagePath(res?.product?.images[0]) || require("../Assets/images/cakes.png")}
                                                    alt={res?.product?.name}
                                                />
                                                <div className="product-desc">
                                                    <h4>{res?.product?.name || 'N/A'}</h4>
                                                    <div className="d-flex">
                                                        <div>
                                                            <p>{res?.size_id?.name || 'N/A'}</p>
                                                            <h5 className="mb-0">${item.totalPrice || '0'}</h5>
                                                        </div>
                                                        {
                                                            !res?.is_out_of_stock &&
                                                            <div className="quantity-main align-items-end ms-4">
                                                                <button type="button" onClick={() => handleQuantityChange(res._id, -1)}>
                                                                    <img src={require("../Assets/images/minus.svg").default} alt="Decrease" />
                                                                </button>
                                                                <span>{item.quantity || 1}</span>
                                                                <button type="button" onClick={() => handleQuantityChange(res._id, 1)}>
                                                                    <img src={require("../Assets/images/add.svg").default} alt="Increase" />
                                                                </button>
                                                            </div>

                                                        }
                                                    </div>
                                                </div>


                                                {
                                                    res?.is_out_of_stock &&
                                                    <div className='quantity-main align-items-end'>
                                                        <div className="product-not-found">
                                                            <h3>Oops....!</h3>
                                                            <p>Delivery can not be done for this item</p>
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                            <button type="button" onClick={() => handleDeleteCart(res._id)}>
                                                <img src={require("../Assets/images/delete.svg").default} alt="Delete" />
                                                <br />
                                                Delete
                                            </button>
                                        </div>
                                    </>
                                )
                            })
                        ) : (
                            <p className='placeholder-cart'>No Product Added</p>
                        )
                    }

                    {/* <div className="add-products-left mb-3">
                        <div className="add-product-card">
                            <img
                                className="product-img"
                                src={require("../Assets/images/cakes.png")}
                            />
                            <div className="product-desc">
                                <h4>Iced Coffee & Milk</h4>
                                <p>Regular</p>
                                <h5>$30.00</h5>
                            </div>
                            <div className="quantity-main align-items-end">
                                <button onClick={decrementQuantity}>
                                    {" "}
                                    <img
                                        src={
                                            require("../Assets/images/minus.svg").default
                                        }
                                    />
                                </button>

                                <span>{quantity}</span>
                                <button onClick={incrementQuantity}>
                                    <img
                                        src={
                                            require("../Assets/images/add.svg").default
                                        }
                                    />
                                </button>

                                <div className="product-not-found">
                                    <h3>Oops....!</h3>
                                    <p>Delivery can not be done for this item</p>
                                </div>
                            </div>
                        </div>
                        <button type="button">
                            <img
                                src={require("../Assets/images/delete.svg").default}
                            />
                            <br />
                            Delete
                        </button>
                    </div> */}

                </div>
            </Col>

            <DeleteModal deleteAccOpen={deleteAccOpen} setDeleteAccOpen={setDeleteAccOpen} type={"cart"} id={cartId} />
        </>
    )
}

export default CartCard