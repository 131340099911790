import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import LoginScreen from "./Pages/LoginScreen";
import ForgotPassword from "./Pages/ForgotPassword";
import OtpVerification from "./Pages/OtpVerification";
import ResetPassword from "./Pages/ResetPassword";
import PasswordChanged from "./Pages/PasswordChanged";
import CreateAccount from "./Pages/CreateAccount";
import LoginOtpVerification from "./Pages/LoginOtpVerification";
import AllowLocation from "./Pages/AllowLocation";
import CreateProfile from "./Pages/CreateProfile";
import AddAddress from "./Pages/AddAddress";
import SingleRestaurant from "./Pages/SingleRestaurant";
import SingleDish from "./Pages/SingleDish";
import Cart from "./Pages/Cart";
import OrderListing from "./Pages/OrderListing";
import SingleOrder from "./Pages/SingleOrder";
import SingleOrderTakeaway from "./Pages/SingleOrderTakeaway";
import MyProfile from "./Pages/MyProfile";
import Support from "./Pages/Support";
import SubscribedProduct from "./Pages/SubscribedProduct";
import SubscribedProducts from "./Pages/SubscribedProducts";
import PrivateRoute from "./Route/PrivateRoutes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ViewAllPages from "./Pages/ViewAllPages";
import PublicRoute from "./Route/PublicRoutes";
import PaypalComp from "./Components/PaypalComp";
import BecomePartner from "./Pages/BecamePartner";
import DeliverWithUs from "./Pages/DeliverwithUs";

function App() {


  return (
    <>
      <div className="App">
        {/* <PaypalComp /> */}
        <BrowserRouter>
          <Routes>
            <Route element={<PublicRoute />}>
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/otp-verification/:email"
                element={<OtpVerification />}
              />
              <Route path="/create-profile" element={<CreateProfile />} />
              <Route path="/add-address" element={<AddAddress />} />
              <Route path="/reset-password/:id" element={<ResetPassword />} />
              <Route path="/allow-location" element={<AllowLocation />} />
              <Route path="/password-changed" element={<PasswordChanged />} />
              <Route path="/LoginOtp" element={<LoginOtpVerification />} />
              <Route path="/BecamePartner" element={<BecomePartner />} />
              <Route path="/deliver-with-us" element={<DeliverWithUs />} />
            </Route>

            <Route path="/" element={<Home />} />
            <Route
              path="/single-restaurant/:id"
              element={<SingleRestaurant />}
            />
            <Route path="/single-dish/:id" element={<SingleDish />} />
            <Route path="/ViewAllPages" element={<ViewAllPages />} />

            <Route element={<PrivateRoute />}>
              <Route path="/cart" element={<Cart />} />
              <Route path="/order-listing" element={<OrderListing />} />
              <Route path="/single-order/:id" element={<SingleOrder />} />
              <Route
                path="/single-order-takeaway"
                element={<SingleOrderTakeaway />}
              />
              <Route path="/my-profile" element={<MyProfile />} />
              <Route path="/support" element={<Support />} />
              <Route
                path="/subscribed-product"
                element={<SubscribedProduct />}
              />
              <Route
                path="/subscribed-products"
                element={<SubscribedProducts />}
              />
            </Route>
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
