import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Form as FormikForm, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../Redux/Slice/languageSlice';

const LangSelectModal = ({ langShowModal, setLangShowModal }) => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state?.lang?.currentLanguage);

  const [initialValues, setInitialValues] = useState({
    language: '',
  });

  // Update initialValues when currentLanguage changes
  useEffect(() => {
    setInitialValues({ language: currentLanguage });
  }, [currentLanguage]);

  const handleLanguageChange = (value) => {
    setInitialValues({ language: value });
  };

  const handleSubmit = (values) => {
    dispatch(setLanguage(values.language));
    setLangShowModal(false); // Close modal on save
  };

  return (
    <Modal show={langShowModal} onHide={() => setLangShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Select Language</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values }) => (
          <FormikForm>
            <Modal.Body>
              <Form.Group>
                <Form.Check
                  type="radio"
                  label="English"
                  name="language"
                  value="en"
                  checked={values.language === 'en'}
                  onChange={() => handleLanguageChange('en')}
                />
                <Form.Check
                  type="radio"
                  label="French"
                  name="language"
                  value="fr"
                  checked={values.language === 'fr'}
                  onChange={() => handleLanguageChange('fr')}
                />
                <Form.Check
                  type="radio"
                  label="Dutch"
                  name="language"
                  value="nl"
                  checked={values.language === 'nl'}
                  onChange={() => handleLanguageChange('nl')}
                />
                <Form.Check
                  type="radio"
                  label="German"
                  name="language"
                  value="de"
                  checked={values.language === 'de'}
                  onChange={() => handleLanguageChange('de')}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <div className='d-flex gap-2'>
                <button
                  variant="secondary"
                  className="cmn-red-btn mb-0 w-100"
                  onClick={() => setLangShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  variant="primary"
                  type="submit"
                  className="cmn-red-btn mb-0 w-100"
                >
                  Save Changes
                </button>
              </div>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default LangSelectModal;
