import React, { useState } from "react";
import { Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Layout from "../Components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import _ from "lodash";
import { useFormik } from "formik";
import { shopLoginAction } from "../Redux/Action/AuthAction";
import * as Yup from "yup";

export default function BecomePartner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log("values", values)
      setIsLoader(true);
      console.log("values", values);
      let userData = {
        role: 3,
        email: values?.email,
        password: values.password,
      };
      await dispatch(shopLoginAction(userData)).then((res) => {
        console.log("res", res);
        if (res?.payload?.status === 200) {
          localStorage.setItem("token", res?.payload?.data?.token);
          setTimeout(() => {
            window.location.href = '/'
          }, 500)
        } else {
          toast.error(res?.payload?.message);
        }
        setIsLoader(false);
      });
    },
  });




  return (
    <Layout>
      <section className="become-partner-sec">
        <Container>
          <Row className="align-items-end">
            <Col lg={6}>
              <div className="become-partner-banner">
                <p>Join the largest bakery network!</p>
                <h1>
                  Unleash New Profit Opportunities with <span>Milcake</span>
                </h1>
                <img
                  src={require("../Assets/images/banner-right-bt.png")}
                  className="w-100"
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="become-partner-login">
                <div className="card-box-set">
                  <span className="shape-ad-login">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="67"
                      height="67"
                      viewBox="0 0 67 67"
                      fill="none"
                    >
                      <rect
                        x="26.3418"
                        width="14.3162"
                        height="67"
                        fill="#A72A2F"
                      />
                      <rect
                        y="40.6582"
                        width="14.3162"
                        height="67"
                        transform="rotate(-90 0 40.6582)"
                        fill="#A72A2F"
                      />
                      <rect
                        x="4.58105"
                        y="15.8496"
                        width="14.3162"
                        height="67"
                        transform="rotate(-45 4.58105 15.8496)"
                        fill="#A72A2F"
                      />
                      <rect
                        x="51.957"
                        y="4.58203"
                        width="14.3162"
                        height="67"
                        transform="rotate(45 51.957 4.58203)"
                        fill="#A72A2F"
                      />
                    </svg>
                  </span>
                  <div className="login-cmn-box">
                    <div className="login-box-inner-wrap">
                      <h2 className="text-center">Login to Account</h2>
                      <p className="text-center">
                        Please enter your email & password to continue
                      </p>
                      <Form onSubmit={formik.handleSubmit}>
                        <div className="form-set">
                          <Form.Label>Email</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="14"
                                viewBox="0 0 18 14"
                                fill="none"
                              >
                                <path
                                  d="M8.99152 13.9951C7.07519 13.9951 5.15886 14.0087 3.24309 13.9906C1.70014 13.9759 0.268368 12.6922 0.0454608 11.163C0.0201942 10.9887 0.00166537 10.8115 0.00166537 10.636C-0.00114203 8.18593 -1.90675e-05 5.73642 0.00222685 3.28691C0.00278833 2.81461 0.129121 2.36996 0.328447 1.94563C0.434005 1.72161 0.494083 1.71146 0.667019 1.88469C1.19313 2.41172 1.71811 2.94045 2.24366 3.46804C3.49295 4.72299 4.72933 5.99091 5.99546 7.22836C7.66362 8.85911 10.3116 8.87604 11.9803 7.24472C13.7551 5.50902 15.4895 3.73156 17.2419 1.97215C17.279 1.93491 17.3149 1.8971 17.3525 1.86099C17.4957 1.72444 17.5597 1.73177 17.6462 1.90444C17.8539 2.31805 17.9898 2.75706 17.9915 3.22033C17.9982 5.72909 18.0072 8.23785 17.9904 10.746C17.9797 12.2995 16.6821 13.7418 15.1476 13.9517C14.9163 13.9833 14.6804 13.9957 14.4469 13.9963C12.6282 13.9996 10.8102 13.9979 8.99152 13.9979C8.99152 13.9979 8.99152 13.9968 8.99152 13.9951Z"
                                  fill="#8391A1"
                                />
                                <path
                                  d="M9.00051 0.00114491C10.9107 0.00114491 12.8203 -0.00280441 14.7304 0.00396687C15.3581 0.00622397 15.9309 0.212183 16.4547 0.554697C16.6316 0.670373 16.6366 0.720029 16.4867 0.871254C15.6995 1.66462 14.9112 2.4563 14.1235 3.24854C13.151 4.22642 12.1796 5.20431 11.2054 6.18107C9.93313 7.45745 8.0634 7.45689 6.7894 6.17768C5.03646 4.41828 3.28632 2.65661 1.53563 0.896082C1.35034 0.709871 1.35371 0.674324 1.5783 0.532127C2.10104 0.200334 2.66926 0.00452962 3.29025 0.0028368C5.19367 -0.00224166 7.09709 0.00114491 9.00051 0.00114491Z"
                                  fill="#8391A1"
                                />
                              </svg>
                            </InputGroup.Text>
                            <Form.Control
                              className="border-right-o"
                              placeholder="Email Address"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </InputGroup>

                          <Form.Label>Password</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="17"
                                viewBox="0 0 14 17"
                                fill="none"
                              >
                                <path
                                  d="M6.52621 0C6.84228 0 7.15835 0 7.47391 0C7.59105 0.0220721 7.70718 0.0482827 7.82532 0.0657565C9.96509 0.384422 11.6499 2.1718 11.6646 4.14358C11.6696 4.82413 11.6656 5.50469 11.6661 6.18525C11.6661 6.2441 11.6661 6.30342 11.6661 6.37562C11.8554 6.37562 12.0241 6.37562 12.1932 6.37562C13.2439 6.377 13.9987 7.06031 13.9992 8.01355C14.0003 10.4594 14.0003 12.9053 13.9992 15.3511C13.9987 16.3163 13.2474 16.9997 12.1861 16.9997C8.72909 17.0001 5.27204 17.0001 1.81449 16.9997C0.75369 16.9997 0.000883575 16.3154 0.000378675 15.3516C-0.000126225 12.9113 -0.000126225 10.4709 0.000378675 8.03056C0.000378675 7.05617 0.750661 6.37608 1.82458 6.37562C1.99171 6.37562 2.15933 6.37562 2.33352 6.37562C2.33352 5.65919 2.32696 4.9676 2.33706 4.27647C2.34059 4.01804 2.35321 3.75456 2.41027 3.50211C2.79399 1.80393 3.90376 0.692052 5.71585 0.16692C5.9789 0.0910474 6.25559 0.0547204 6.52621 0ZM10.1039 6.37056C10.1039 5.65322 10.106 4.95151 10.1024 4.25026C10.1019 4.10725 10.0878 3.96332 10.0671 3.82169C9.85401 2.35942 8.28781 1.25535 6.6797 1.43147C5.062 1.6085 3.90275 2.78982 3.89619 4.27003C3.89366 4.91702 3.89568 5.56401 3.89568 6.211C3.89568 6.26342 3.89568 6.31538 3.89568 6.37056C5.97587 6.37056 8.02678 6.37056 10.1039 6.37056ZM7.00183 9.21372C6.33031 9.21234 5.74109 9.60044 5.52651 10.1854C5.31748 10.7555 5.53459 11.3915 6.07736 11.7483C6.19702 11.827 6.23388 11.9065 6.23186 12.0339C6.22428 12.4983 6.22731 12.9632 6.22933 13.4277C6.23135 13.8571 6.56055 14.1657 7.00688 14.1611C7.44715 14.1565 7.76776 13.8525 7.76978 13.4309C7.77231 12.9554 7.77382 12.4795 7.76776 12.004C7.76625 11.8927 7.80412 11.8265 7.9051 11.7607C8.45998 11.3998 8.68466 10.7661 8.47412 10.1886C8.26055 9.60228 7.67638 9.2151 7.00183 9.21372Z"
                                  fill="#8391A1"
                                />
                              </svg>
                            </InputGroup.Text>
                            <Form.Control
                              className="border-right-o"
                              placeholder="Password"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />

                            <button type="button" className="pass-show">
                              <img
                                src={
                                  require("../Assets/images/eye.svg").default
                                }
                                alt="eye icon"
                              />
                            </button>
                          </InputGroup>
                        </div>

                        <button
                          variant="primary"
                          type="submit"
                          className="submit"
                        >
                          Login
                        </button>
                        <div className="text-center mt-5 login-bottom-line">
                          <p>
                            Don’t have an account?
                            <Link to="/CreateAccount">Register Now</Link>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="overview-count-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="overview-count-contain">
                <div className="overview-count-item">
                  <h2>546+</h2>
                  <p>Registered Riders</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>789,900+</h2>
                  <p>Orders Delivered</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>690+</h2>
                  <p>Restaurants Partnered</p>
                </div>
                <div className="overview-count-line"></div>
                <div className="overview-count-item">
                  <h2>17,457+</h2>
                  <p>Food items</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="partner-heading-text">
                <h2>
                  Become a <span> Partner Restaurant </span> to get Exciting
                  offers
                </h2>
              </div>
              <div className="restaurant-section">
                <div className="restaurant-left-side">
                  <img
                    src={require("../Assets/images/restaurant-left-img.png")}
                  />
                </div>
                <div className="you-receive-section">
                  <div className="visibility-right">
                    <h2>You Receive </h2>
                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>Increased Visibility</h3>
                        <p>
                          Boost your business with enhanced visibility on our
                          platform. Reach more customers and expand your
                          presence in the dessert-loving community."
                        </p>
                      </div>
                    </div>

                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>Reduced delivery commission </h3>
                        <p>
                          Enjoy lower delivery commissions when you partner with
                          us. Maximize your profits and keep more of what you
                          earn from each order."
                        </p>
                      </div>
                    </div>

                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>Sponsorship opportunities</h3>
                        <p>
                          Unlock sponsorship opportunities to further promote
                          your offerings. Collaborate with brands and enhance
                          your market presence through strategic partnerships."
                        </p>
                      </div>
                    </div>
                    <div className="increased-text">
                      <img
                        src={require("../Assets/images/red-right-icon.png")}
                      />
                      <div className="text-disp">
                        <h3>More Orders</h3>
                        <p>
                          "Drive growth with a steady influx of orders. Tap into
                          our network of customers eager to indulge in your
                          delicious desserts."
                        </p>
                      </div>
                    </div>
                    <div className="bott-image">
                      <img src={require("../Assets/images/bott-cake.png")} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="partner-heading-text why-cake">
                <h2>
                  Why <span> Milcake </span> ?
                </h2>
              </div>
              <div className="milcake-grid">
                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/deliver-icon.svg").default}
                  />
                  <h2>Deliver your way </h2>
                  <p>
                    Our offerings are flexible so you can customize them to your
                    needs. Get started with your delivery people through the
                    Milcake platform.
                  </p>
                  <div className="deliver-bottom-image">
                    <img src={require("../Assets/images/pngegg.svg").default} />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img src={require("../Assets/images/boost.svg").default} />
                  <h2>Boost your visibility </h2>
                  <p>
                    Stand out with in-app marketing to reach even more customers
                    and increase sales.
                  </p>
                  <div className="deliver-bottom-image">
                    <img src={require("../Assets/images/bott-cake.png")} />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/customers-icon.svg").default}
                  />
                  <h2>Connect with customers </h2>
                  <p>
                    Our offerings are flexible so you can customize them to your
                    needs. Get started with your delivery people through the
                    Milcake platform.
                  </p>
                  <div className="deliver-bottom-image">
                    <img
                      src={
                        require("../Assets/images/customers-bott-icon.svg")
                          .default
                      }
                    />
                  </div>
                </div>

                <div className="deliver-your-way">
                  <img
                    src={require("../Assets/images/growth-icon.svg").default}
                  />
                  <h2>Unlock new growth </h2>
                  <p>
                    Connect with thousands of dessert lovers in your area by
                    partnering with our milcake platform.
                  </p>
                  <div className="deliver-bottom-image">
                    <img
                      src={
                        require("../Assets/images/growth-bott-icon.svg").default
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-restaurant-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className=" about-us-section">
                <div className="about-left-side-image">
                  <img src={require("../Assets/images/about-image.png")} />
                </div>
                <div className="about-disp-right-side">
                  <h2>
                    About <span>Us</span>
                  </h2>
                  <p>
                    we prioritize your flexibility and reward your dedication.
                    Our flexible working hours empower you to manage your
                    schedule effectively.Our comprehensive insurance coverage
                    offers peace of mind, ensuring you're protected from
                    unexpected challenges.
                  </p>
                  <button>Download App</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="three-easy-steps">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="easy-steps">
                <div className="cricle-image">
                  <img src={require("../Assets/images/cricle.png")} />
                </div>
                <div className="step-image">
                  <img src={require("../Assets/images/get-steps-image.png")} />
                </div>
                <div className="get-star-ted">
                  <h2>Get Started in 3 Easy Steps</h2>
                  <div className="steps-grid">
                    <div className="regi-ster-shop">
                      <img
                        src={
                          require("../Assets/images/register-shop.svg").default
                        }
                      />
                      <h4>Register your Shop</h4>
                      <p>
                        Download our app now to become a rider. Enjoy flexible
                        hours and competitive earnings.
                      </p>
                    </div>
                    <div className="regi-ster-shop left-right-line">
                      <img
                        src={
                          require("../Assets/images/upload-menu.svg").default
                        }
                      />
                      <h4>Upload your menu</h4>
                      <p>
                        Congratulations on taking the first step! Now, it's time
                        to complete your registration.
                      </p>
                    </div>
                    <div className="regi-ster-shop  left-right-line">
                      <img
                        src={
                          require("../Assets/images/dashboard-icon.svg").default
                        }
                      />
                      <h4>Access Restaurant Dashboard and go live!</h4>
                      <p>
                        Start earning today by completing rides with our milk
                        cake delivery service
                      </p>
                    </div>
                  </div>
                  <div className=" get-started-btn">
                    <button>Get Started</button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="lep-top-section">
                <img src={require("../Assets/images/leptop.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="contact-us-sec">
                <div className="partner-heading-text contact-heading">
                  <h2>
                    Contact <span>Us</span>
                  </h2>
                </div>
                <div className="contact-form">
                  <div className="cont-dep">
                    <p>
                      Have questions or need assistance? Reach out to us! Our
                      team is here to help with any inquiries or support you may
                      need regarding our milk cake delivery service.
                    </p>
                  </div>
                  <div className="input-form login-box-inner-wrap">
                    <Form>
                      <div className="form-set">
                        <InputGroup className="mb-4">
                          <InputGroup.Text id="basic-addon1">
                            <svg
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.01764 8.28074C6.65651 8.28074 7.29538 8.27928 7.93416 8.28098C9.98984 8.28643 11.6762 9.63656 11.9715 11.5343C12.0168 11.8251 12.0074 12.1366 11.9482 12.4251C11.8685 12.8136 11.5917 13.0932 11.206 13.2678C10.5243 13.5762 9.79231 13.7156 9.05053 13.8185C7.67154 14.0097 6.28314 14.0247 4.89473 13.9771C3.71798 13.9368 2.54602 13.8355 1.41685 13.491C1.17897 13.4185 0.945013 13.3276 0.721425 13.2225C0.126384 12.9429 -0.0369937 12.4501 0.00666074 11.8765C0.139715 10.1274 1.42617 8.73858 3.26471 8.36311C3.52568 8.30982 3.79823 8.28886 4.066 8.28431C4.71637 8.27335 5.36709 8.28074 6.01764 8.28074Z"
                                fill="#8391A1"
                              />
                              <path
                                d="M6.01206 4.42833e-06C8.06565 0.00309138 9.70535 1.53495 9.70256 3.4478C9.69986 5.32832 8.03829 6.86343 6.00709 6.86213C3.95386 6.86083 2.31146 5.31727 2.31668 3.39386C2.32183 1.52447 3.98505 -0.00300128 6.01206 4.42833e-06Z"
                                fill="#8391A1"
                              />
                            </svg>
                          </InputGroup.Text>
                          <Form.Control
                            className="border-right-o"
                            placeholder="Name"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>

                        <InputGroup className="mb-4">
                          <InputGroup.Text id="basic-addon1">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.48667 1.89312C6.34828 1.89312 6.22357 1.89312 6.09874 1.89312C5.48091 1.89312 4.86286 1.90349 4.24525 1.89015C3.64848 1.87724 3.24446 2.31953 3.28725 2.84826C3.30257 3.03836 3.29155 3.23089 3.28923 3.42232C3.28493 3.77738 3.04852 4.01765 2.70823 4.01589C2.37511 4.01412 2.14178 3.77683 2.14542 3.42651C2.14906 3.0774 2.13021 2.72267 2.18468 2.38051C2.32119 1.52262 3.14092 0.796179 4.01215 0.770266C4.90256 0.743912 5.79429 0.758577 6.68548 0.760783C6.80346 0.761114 6.88892 0.729908 6.97416 0.645553C7.63687 -0.010871 8.40521 -0.161056 9.27622 0.169307C10.4586 0.617765 11.6445 1.05696 12.8271 1.50509C13.5074 1.76301 13.9961 2.48195 13.9985 3.2686C14.0062 5.72945 14.0076 8.1904 13.9981 10.6512C13.9946 11.5691 13.5399 12.2023 12.6824 12.5302C11.5164 12.9761 10.3464 13.411 9.17885 13.8528C8.50622 14.1073 7.86986 14.0291 7.2731 13.6396C7.15224 13.5608 7.06634 13.43 6.95188 13.3389C6.88628 13.2868 6.79575 13.2368 6.71602 13.2361C5.8631 13.2288 5.01018 13.2365 4.15726 13.2301C3.20135 13.223 2.39805 12.6158 2.20784 11.6952C2.12304 11.2847 2.14829 10.8477 2.15733 10.4235C2.16196 10.2035 2.40069 10.0512 2.67537 10.0324C2.93075 10.015 3.17257 10.1682 3.25075 10.4065C3.27578 10.4828 3.28647 10.5665 3.28824 10.6472C3.29331 10.8767 3.28625 11.1065 3.29133 11.3361C3.30037 11.7463 3.64154 12.1133 4.04776 12.1541C4.10202 12.1595 4.15704 12.1572 4.21173 12.1572C4.96585 12.1573 5.71997 12.1573 6.48689 12.1573C6.48667 8.7381 6.48667 5.32907 6.48667 1.89312Z"
                                fill="#8391A1"
                              />
                              <path
                                d="M3.46125 6.52933C3.31426 6.38389 3.16551 6.2401 3.02051 6.09267C2.78531 5.85361 2.77318 5.52589 2.98831 5.30029C3.20289 5.07523 3.56027 5.06961 3.79657 5.30029C4.23489 5.72801 4.6667 6.16236 5.10126 6.59395C5.19124 6.68337 5.29908 6.76111 5.3669 6.8651C5.46933 7.0219 5.44739 7.15841 5.31694 7.29338C4.99055 7.63112 4.65964 7.96469 4.32917 8.29847C4.1599 8.46938 3.98998 8.63985 3.81565 8.8057C3.57295 9.03649 3.23002 9.02822 3.0322 8.79191C2.8228 8.54172 2.87705 8.19118 3.15603 7.99082C3.27346 7.90646 3.39079 7.82222 3.49675 7.70148C3.43942 7.70148 3.38208 7.70148 3.32474 7.70148C2.45539 7.70148 1.58593 7.70159 0.716574 7.70126C0.645562 7.70126 0.574329 7.70059 0.503537 7.6953C0.190818 7.67192 0.0113017 7.48028 0.000385186 7.16083C-0.00997998 6.85627 0.189825 6.6117 0.481373 6.57675C0.557127 6.5677 0.634094 6.56726 0.710509 6.56715C1.56343 6.56649 2.41646 6.56671 3.26938 6.56671C3.32805 6.56671 3.3866 6.56671 3.44526 6.56671C3.45077 6.55414 3.45607 6.54179 3.46125 6.52933Z"
                                fill="#8391A1"
                              />
                            </svg>
                          </InputGroup.Text>
                          <Form.Control
                            className="border-right-o"
                            placeholder="Email"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>

                        <InputGroup className="mb-3 text-border">
                          <InputGroup.Text id="basic-addon1">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_1894_35474)">
                                <path
                                  d="M13.5657 5.1845C13.0559 4.75592 12.276 4.80697 11.8334 5.3347L11.5674 5.64937L13.4526 7.23118L13.7066 6.91766C14.1434 6.39813 14.0881 5.62222 13.5657 5.1845ZM8.24139 9.58469L8.10109 9.75072C7.97889 9.89721 7.89213 10.0699 7.84756 10.2554L7.46625 11.8512C7.4486 11.9252 7.45191 12.0028 7.47582 12.0751C7.49973 12.1474 7.54329 12.2116 7.60163 12.2605C7.7174 12.3578 7.8807 12.3861 8.02822 12.323L9.53385 11.6701C9.70845 11.594 9.86318 11.4788 9.98606 11.3333L10.1269 11.1667L8.24139 9.58469ZM11.0381 6.27563L8.77071 8.95843L10.6561 10.5404L12.9234 7.85744L11.0381 6.27563ZM10.2825 1.81824L8.72436 0.337824C8.57706 0.19818 8.39586 0.11593 8.20486 0.0625V2.46071H10.6399C10.589 2.21708 10.4648 1.99138 10.2825 1.81824Z"
                                  fill="#8391A1"
                                />
                                <path
                                  d="M7.04991 10.065C7.12322 9.75816 7.2694 9.46777 7.47207 9.22504L10.666 5.44575V3.28125H7.79492C7.56821 3.28125 7.38477 3.0978 7.38477 2.87109V0H1.23242C0.553914 0 0.00195312 0.551961 0.00195312 1.23047V12.7695C0.00195312 13.448 0.553914 14 1.23242 14H9.43555C10.1141 14 10.666 13.448 10.666 12.7695V11.7999L10.6128 11.863C10.4113 12.1029 10.1513 12.2964 9.86173 12.4226L8.35489 13.0759C7.93019 13.2593 7.43858 13.1943 7.07477 12.8896C6.71547 12.588 6.55967 12.1174 6.66861 11.6607L7.04991 10.065ZM2.05273 2.46094H6.1543C6.381 2.46094 6.56445 2.64439 6.56445 2.87109C6.56445 3.0978 6.381 3.28125 6.1543 3.28125H2.05273C1.82603 3.28125 1.64258 3.0978 1.64258 2.87109C1.64258 2.64439 1.82603 2.46094 2.05273 2.46094ZM2.05273 4.94922H8.61523C8.84194 4.94922 9.02539 5.13267 9.02539 5.35938C9.02539 5.58608 8.84194 5.76953 8.61523 5.76953H2.05273C1.82603 5.76953 1.64258 5.58608 1.64258 5.35938C1.64258 5.13267 1.82603 4.94922 2.05273 4.94922ZM5.33398 10.6914H2.05273C1.82603 10.6914 1.64258 10.508 1.64258 10.2812C1.64258 10.0545 1.82603 9.87109 2.05273 9.87109H5.33398C5.56069 9.87109 5.74414 10.0545 5.74414 10.2812C5.74414 10.508 5.56069 10.6914 5.33398 10.6914ZM2.05273 8.23047C1.82603 8.23047 1.64258 8.04702 1.64258 7.82031C1.64258 7.59361 1.82603 7.41016 2.05273 7.41016H6.97461C7.20132 7.41016 7.38477 7.59361 7.38477 7.82031C7.38477 8.04702 7.20132 8.23047 6.97461 8.23047H2.05273Z"
                                  fill="#8391A1"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1894_35474">
                                  <rect width="14" height="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </InputGroup.Text>
                          <Form.Control
                            as="textarea" // Change to textarea
                            className="border-right-none"
                            placeholder="Add Description"
                            aria-label="Message"
                            aria-describedby="basic-addon1"
                          />
                        </InputGroup>
                      </div>
                    </Form>

                    <div className="send-btn">
                      <button>Send</button>
                    </div>
                    <div className="contact-cake">
                      <img
                        src={
                          require("../Assets/images/contact-us-image.svg")
                            .default
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
