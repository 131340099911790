import React from "react";
import { Col } from "react-bootstrap";

export default function BillingDetails({ billState }) {
  return (
    <Col lg={12} className="mt-3">
      <div className="instant-delievery">
        <div className="billing-details-box">
          <div className="left-side">
            <h3>Billing Details</h3>
            <ul>
              <li>Quantity</li>
              <li>{billState?.total_items}</li>
            </ul>
            <ul>
              <li>Item Total</li>
              <li>${billState?.items_total}</li>
            </ul>
            <ul>
              <li>GST and Restaurant Charges</li>
              <li>$0</li>
            </ul>
            <ul>
              <li>Delivery Fees</li>
              <li>{billState?.delivery_fees ? `+$${billState?.delivery_fees}` : '$0'}</li>
            </ul>
          </div>
          <div className="right-side">
            <ul>
              <li>
                <b>Grand Total</b>
              </li>
              <li>${billState?.grand_total} </li>
            </ul>
            <ul>
              <li>Promocode Applied</li>
              <li>{billState?.promo_discount ? `-$${billState?.promo_discount}` : '$0'}</li>
            </ul>
            <ul>
              <li className="ad-red-color">To Pay</li>
              <li className="ad-red-color">${billState?.to_pay}</li>
            </ul>
          </div>
        </div>
      </div>
    </Col>
  );
}
