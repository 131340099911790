import { createSlice } from "@reduxjs/toolkit";
import { dealofdayAction, favortieAction, getNotificationListAction, listCategoriesAction, listShopAction, prepareOrderAction, productDetailsAction, productListAction, searchAction, shopDetailsAction, shopViewAllAction, similarProductListAction } from "../Action/HomeAction";

const initialState = {
    shopList: [],
    categoryList: [],
    shopDetails: {},
    productList: [],
    productDetails: {},
    searchData: [],
    similarProductList: [],
    dealOfDayList: [],
    favList: [],
    shopViewAllList: [],
    notifyList: [],
    prepareOrderData: {}
}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(listShopAction.fulfilled, (state, action) => {
                state.shopList = action?.payload;
            })
            .addCase(shopDetailsAction.fulfilled, (state, action) => {
                state.shopDetails = action?.payload;
            })
            .addCase(listCategoriesAction.fulfilled, (state, action) => {
                state.categoryList = action?.payload?.data;
            })
            .addCase(productListAction.fulfilled, (state, action) => {
                state.productList = action?.payload;
            })
            .addCase(productDetailsAction.fulfilled, (state, action) => {
                state.productDetails = action?.payload;
            })
            .addCase(searchAction.fulfilled, (state, action) => {
                state.searchData = action?.payload;
            })
            .addCase(similarProductListAction.fulfilled, (state, action) => {
                state.similarProductList = action?.payload;
            })
            .addCase(dealofdayAction.fulfilled, (state, action) => {
                state.dealOfDayList = action?.payload;
            })
            .addCase(favortieAction.fulfilled, (state, action) => {
                state.favList = action?.payload;
            })
            .addCase(shopViewAllAction.fulfilled, (state, action) => {
                state.shopViewAllList = action?.payload;
            })
            .addCase(getNotificationListAction.fulfilled, (state, action) => {
                state.notifyList = action?.payload;
            })
            .addCase(prepareOrderAction.fulfilled, (state, action) => {
                state.prepareOrderData = action?.payload;
            });
    },
});

export default homeSlice.reducer;